const config = {
  ENVIRONMENT_NAME: 'dev',
  forwoodId: {
    URL: 'https://id.dev.droopy.forwoodsafety.com',
    USER_TOKEN_URL: 'https://caju1r7ht8.execute-api.us-west-2.amazonaws.com/prod'
  },
  apiGateway: {
    REGION: 'us-west-2',
    URL: 'https://5knsprh0ll.execute-api.us-west-2.amazonaws.com/prod'
  },
  reactApp: {
    VERSION: 'admin-container-1.10.0',
    HOSTNAME: 'https://admin.dev.droopy.forwoodsafety.com',
    COMPANY_MICROFRONTEND_URL: 'https://company.dev.droopy.forwoodsafety.com',
    PHYSICAL_LOCATION_MICROFRONTEND_URL: 'https://locations.dev.droopy.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.dev.droopy.forwoodsafety.com',
    WEBSOCKET: 'wss://uvluzxok9f.execute-api.us-west-2.amazonaws.com/dev'
  },
  get forwoodIdAuthUrl() {
    return `${this.forwoodId.URL}/authorize?response_type=token&scope=openid&client_id=${this.forwoodId.APP_CLIENT_ID}&redirect_uri=${this.reactApp.HOSTNAME}&state=`;
  }
};

export default config;